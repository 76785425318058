import { borderRadius, hoverRadius } from 'config/theme/borderRadius';

import CallToActionBlock from 'libs/wordpress/content/cmsBlocks/CallToActionBlock';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import moduleSpacing from 'config/theme/moduleSpacing';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';

const InnerWrapper = styled('div')`
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: border-radius ${transitions.primary};

    ${media.hover} {
        :hover {
            border-radius: ${hoverRadius};
        }
    }
`;

const CallToActionModule = ({ isHero = false, ...rest }) => {
    const clientHeight = useClientHeight();
    const headerHeights = useHeaderHeights();

    if (isHero) {
        return (
            <MaxWidthWrapper
                as="section"
                height={`calc(${clientHeight} - ${headerHeights.banner}px)`}
                padding={[0, null, null, null, null, '16px']}
                marginBottom={['24px', null, '16px', null, null, '24px']} // Custom moduleSpacing for hero
            >
                <InnerWrapper borderRadius={[0, null, null, null, null, borderRadius[2]]}>
                    <CallToActionBlock {...rest} />
                </InnerWrapper>
            </MaxWidthWrapper>
        );
    }

    return (
        <MaxWidthWrapper
            includeContentSpacing
            as="section"
            aspectRatio={[ratios.vertical, null, ratios.square, null, 'none']}
            height={['auto', null, null, null, clientHeight]}
            py={moduleSpacing.md}
        >
            <InnerWrapper borderRadius={borderRadius}>
                <CallToActionBlock {...rest} />
            </InnerWrapper>
        </MaxWidthWrapper>
    );
};

CallToActionModule.propTypes = {
    isHero: PropTypes.bool,
};

export default CallToActionModule;
